<template>
  <backoffice-container>
    <!-- General -->
    <div v-if="isLoading">
      <b-spinner
        type="grow"
        small
        variant="primary"
        class="mx-auto mt-3 d-block"
      />
    </div>
    <div v-else>
      <b-card>
        <!-- Unshare -->
        <div class=" trash-icon">
          <feather-icon
            v-b-tooltip.hover.bottom
            icon="Trash2Icon"
            :title="$t('unshare.tooltip')"
            size="20"
            class="text-primary pointer share-icon "
            @click="isUnshareModalVisible = true"
          />
        </div>
        <div class="d-flex flex-wrap justify-content-between mt-2">
          <h4>{{ $t('backoffice.roles.role-detail') }}</h4>
          <b-button variant="primary" :disabled="isDisabled" @click="onSubmit()">
            {{ $t('form.actions.save') }}
          </b-button>
        </div>
        <!-- Body -->
        <validation-observer>
          <!-- Form -->
          <b-form>
            <b-row>
              <b-col cols="12" lg="6">
                <!-- Name -->
                <b-form-group class="flex-grow-1 mb-1">
                  <label for="rol-name" class="font-weight-bold"> {{ $t('backoffice.roles.name') }}*</label>
                  <form-translation-table
                    v-model="currentClassifier.name"
                    class="mt-50"
                    :value-placeholder="$t('layouts.widget-name')"
                    label-attribute="name"
                    value-attribute="text"
                    @input="handleTitleChange"
                  />
                  <small v-if="isValid === false" class="text-danger mt-25">
                    {{ $t('backoffice.classifiers.validator-message') }}
                  </small>
                </b-form-group>
                <b-form-group>
                  <label for="rol-name" class="font-weight-bold"> {{ $tc('backoffice.classifiers.model', 1) }}*</label>
                  <v-select
                    v-model="currentClassifier.modelType"
                    class="select-control p-0"
                    :placeholder="$t('form.select-multiple-placeholder')"
                    clearable
                    :searchable="false"
                    :multiple="true"
                    :options="modelOptions"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="rol-name" class="font-weight-bold"> {{ $tc('backoffice.custom-fields.type') }}*</label>
                  <v-select
                    v-model="currentClassifier.typeField"
                    class="select-control p-0"
                    :placeholder="$t('form.select-multiple-placeholder')"
                    :clearable="false"
                    :searchable="false"
                    :get-option-label="(a) => a.name"
                    :options="typeFields"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <!-- Description -->
                <b-form-group label-for="description">
                  <label class="font-weight-bold">{{ $t('backoffice.roles.description') }}</label>
                  <form-translation-table
                    v-model="currentClassifier.description"
                    class="mt-50"
                    :value-placeholder="$t('layouts.widget-name')"
                    :is-description="true"
                    label-attribute="description"
                    value-attribute="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
    <unshare-modal v-model="isUnshareModalVisible" :model-type="'custom-field'" @remove-item="handleRemoveItem" />
  </backoffice-container>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import vSelect from 'vue-select';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ClassifiersPlaceholder from '@/assets/images/placeholders/light/classifiers.svg';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'CustomFieldDetail',
  components: {
    ValidationObserver,
    BackofficeContainer,
    vSelect,
    FormTranslationTable,
    UnshareModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentClassifier: {
        name: '',
        description: '',
        modelType: '',
        typeField: '',
        share: false,
      },
      subClassifiers: [],
      isValid: null,
      isEditingClassifier: false,
      required,
      isLoading: false,
      existSomeName: false,
      isValidName: null,
      isSmall: false,
      page: 1,
      perPage: 15,
      isUnshareModalVisible: false,
      uploadFileVisible: false,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    isDisabled() {
      return Object.values(this.currentClassifier?.name).length <= 0 || this.isValid === false || this.currentClassifier?.modelType?.length === 0;
    },
    typeFields() {
      return [
        { name: this.$t('backoffice.custom-fields.field-type.text') },
        { name: this.$t('backoffice.custom-fields.field-type.number') },
        { name: this.$t('backoffice.custom-fields.field-type.date') },
        { name: this.$t('backoffice.custom-fields.field-type.boolean') },
      ];
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    type() {
      return this.types?.filter(({ key }) => key === this.$route.params.id)[0];
    },
    total() {
      return this.$store.getters.type?.meta?.total;
    },
    modelOptions() {
      const modelTypesObj = this.$store.getters.modelTypes;
      const notApply = [
        'chat-individual',
        'chat-group',
        'chat',
        'map',
        'calendar',
        'meeting',
        'auditorium',
        'workspace',
        'community',
        'analytic',
        'payment',
        'classifier',
        'custom-field',
        'live-streaming'
      ];
      let newObj = [];
      //use only correct apps
      modelTypesObj.forEach((app) => {
        if(!notApply.includes(app.addonSingular)){
          newObj.push(app.addon[this.locale]);
        }
      });
      return newObj;
      //return this.$store.getters.modelTypes.map((item) => item.addon[this.locale]);
    },
    allModelOptions() {
      return this.$store.getters.modelTypes;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    types() {
      return this.$store.getters.customFieldsBO.unpaginated;
    },
    classifiers() {
      return this.$store.getters.classifiersBO;
    },
    classifiersPlaceholder() {
      return ClassifiersPlaceholder;
    },
    model() {
      let model = [];
      if (Array.isArray(this.type?.modelType)) {
        model = this.$store.getters.modelTypes.filter((item) => this.type?.modelType.includes(item?.addonSingular));
        return model.map((item) => item?.addon[this.locale]);
      }
      return this.$store.getters.modelTypes.filter((item) => item?.addonSingular === this.type?.modelType)[0]?.addon[
        this.locale
      ];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('backoffice.roles.name'),
        },
        {
          key: 'preview',
          label: this.$t('backoffice.classifiers.preview'),
        },
        {
          key: 'timesUsed',
          label: this.$t('backoffice.classifiers.times-used'),
        },
        {
          key: 'creation',
          label: this.$tc('backoffice.classifiers.creation-date'),
        },
        {
          key: 'edit',
          label: this.$tc('backoffice.classifiers.actions'),
        },
      ];
    },
    typeField() {
      return {
        text: this.$t('backoffice.custom-fields.field-type.text'),
        number: this.$t('backoffice.custom-fields.field-type.number'),
        date: this.$t('backoffice.custom-fields.field-type.date'),
        boolean: this.$t('backoffice.custom-fields.field-type.boolean'),
      };
    },
  },
  watch: {
    locale() {
      this.currentClassifier = {
        name: this.type?.name,
        description: this.type?.description ? this.type?.description : {},
        modelType: this.model,
        typeField: this.typeFields.filter(({ name }) => name === this.typeField[this.type?.type]),
      };
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchClassifiersType();
    await this.getModelTypes();
    this.currentClassifier = {
      name: this.type?.name,
      description: this.type?.description ? this.type?.description : {},
      modelType: this.model,
      typeField: this.typeFields.filter(({ name }) => name === this.typeField[this.type?.type]),
    };
    this.isLoading = false;
    this.updateBreadcrumbs();
  },
  methods: {
    async fetchClassifiersType() {
      await this.$store.dispatch('getItems', {
        itemType: 'customfield',
        customName: 'customFieldsBO',
        forceAPICall: true,
        requestConfig: {
          page: this.$route.params.page,
          perPage: 15,
        },
      });
    },
    translateTranslationTable,
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: 'backoffice.custom-fields.name',
          to: { name: 'custom-fields' },
        },
        {
          text: this.currentClassifier.name
            ? this.translateTranslationTable(this.locale, this.currentClassifier.name)
            : 'backoffice.custom-fields.field-detail',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async onSubmit() {
      const settings = {
        name: this.currentClassifier.name,
        description: this.currentClassifier.description,
        customFieldKey: this.$route.params.id,
        modelType: Array.isArray(this.currentClassifier.modelType)
          ? this.allModelOptions
            .filter((item) => this.currentClassifier.modelType.includes(item.addon[this.locale]))
            .map((item) => item.addonSingular)
          : this.allModelOptions
            .filter((item) => [this.currentClassifier.modelType].includes(item.addon[this.locale]))
            .map((item) => item.addonSingular),
        type: Object.keys(this.typeField).find(
          (key) => this.typeField[key]
            === (this.currentClassifier.typeField[0]?.name ?? this.currentClassifier.typeField.name),
        ),
      };
      try {
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'editCustomfield',
            customName: 'customFieldsBO',
            requestConfig: settings,
          },
        });
        this.notifySuccess(this.$t('backoffice.custom-fields.messages.edit-success'));
        this.fetchClassifiersType();
      } catch {
        this.notifyError(this.$t('backoffice.custom-fields.messages.edit-error'));
      }
    },
    handleTitleChange() {
      const allTypesExceptCurrent = this.types?.filter((item) => item.key !== this.$route.params.id);
      const completeTitles = Object.values(this.currentClassifier.name).filter((item) => item !== '');
      this.isValid = allTypesExceptCurrent.every((type) => type.name[this.locale] !== this.currentClassifier.name[this.locale])
        && completeTitles.length > 0;
      this.updateBreadcrumbs();
    },
    async getModelTypes() {
      await this.$store.dispatch('getModelTypes');
    },
    handleEditClassifier(item) {
      this.isEditingClassifier = true;
      this.currentSubClassifier.name = item.name;
      this.classifierKey = item.key;
    },
    async handleRemoveItem(response) {
      const classifierType = {
        modelName: 'custom_field',
        tableName: 'custom_fields',
        modelKey: this.type.key,
      };
      if (response === true) {
        try {
          await this.$store.dispatch('unshareModel', { item: classifierType, customName: 'customFieldsBO' });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('backoffice.custom-fields.messages.remove-success', {
                item: this.$t('backoffice.custom-fields.messages.remove-success'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          });
          this.$router.push({ name: 'custom-fields', params: { communityId: this.$route.params.communityId } });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.type-classifier'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.share-icon {
  position: absolute;
  margin-top: -60px;
  right: 0;
  cursor: pointer;
}
</style>
