import { render, staticRenderFns } from "./CustomFieldDetail.vue?vue&type=template&id=2987e0f5&scoped=true&"
import script from "./CustomFieldDetail.vue?vue&type=script&lang=js&"
export * from "./CustomFieldDetail.vue?vue&type=script&lang=js&"
import style0 from "./CustomFieldDetail.vue?vue&type=style&index=0&id=2987e0f5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2987e0f5",
  null
  
)

export default component.exports